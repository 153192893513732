<template>
  <basePolicy slug="informativa-area-contatti"/>
</template>
<script>
import basePolicy from '@/components/basePolicy.vue'
export default {
  components:{
    basePolicy
  }
}
</script>